<template>
  <div class="ApiConcept document-content">
    <div class="docHeader">
      <h2>API概览</h2>
      <div class="updateTime">发布时间：2022-01-21 9:00</div>
    </div>
    <ApiConcept class="markdown-body"></ApiConcept>
  </div>
</template>

<script setup>
import ApiConcept from "./mdDocument/API概览.md";
export default {
  data() {
    return {};
  },
  components: {
    ApiConcept,
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.ApiConcept {
}
</style>